<script>
import AutoNumeric from 'autonumeric';

export default {
  props:{
    value: [String, Number]
  },
  data(){
    return {
      plugin: null,
    }
  },
  mounted(){
    this.plugin = new AutoNumeric(this.$el, {decimalCharacter:",",digitGroupSeparator:".",minimumValue:'0'})
    $(this.$el).change((e)=>{
      this.$emit("input", e.target.value.toValue());
    })
    if(this.value) this.plugin.set(this.value)
  },
  watch:{
    value(v){
      if(!v) return this.plugin.set("")
      this.plugin.set(v.toValue())
    }
  }
}
</script>

<template>
  <input type="text" autocomplete="off">
</template>